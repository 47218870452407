import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet";
import { Link } from "gatsby"
import RightLineArrowIcon from "../components/Icons/RightLineArrowIcon"
import { Section, Nodata, NodataGrid, NodataGridLeft, NodataGridRight, NodataFigure, NodataContent } from "../components/Section"
import PrimaryButton from "../components/Button/PrimaryButton"
import CallNow from "../components/CallNow"
import { StaticImage } from "gatsby-plugin-image"


const ThankyouPage = ({ location }) => (
  <Layout location={location} noFooterForm>
    <Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
    <Seo title="Thank You For Contacting Us - Coast To Coast Carports" description="Thank you for your interest in Coast To Coast Carports. Our representative will get back to you soon. If you have any questions please do not hesitate to ask! Just call us." />
    <Section
      pt="215px"
      pb="90px"
      xpt="54px"
      xpb="60px"
      bgColor="#F2F4F9"
      className="circle-right"
    >
      <div className="container">
        <Nodata>
          <NodataGrid>
            <NodataGridLeft>
              <NodataFigure>
                <StaticImage src="../images/thankyou.png" alt="thankyou" />
              </NodataFigure>
            </NodataGridLeft>
            <NodataGridRight>
              <NodataContent maxWidth="600px">
                <h1>Thank you</h1>
                <p>
                  For your interest in Coast To Coast our metal building experts
                  will provide you a professional and written recommendation
                  with a detailed quote for your upcoming carports project!
                </p>
                <Link to="/">
                  <PrimaryButton
                    icon={<RightLineArrowIcon />}
                    text="Go To Home"
                  />
                </Link>
              </NodataContent>
            </NodataGridRight>
          </NodataGrid>
        </Nodata>
      </div>
    </Section>
    <CallNow />
  </Layout>
)

export default ThankyouPage
